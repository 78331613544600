/* Minimal styles for react-joyride */
.react-joyride__overlay {
    background: rgba(0, 0, 0, 0.4);
    z-index: 9999;
  }
  
  .react-joyride__spotlight {
    border-radius: 4px;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.4);
    z-index: 9999;
  }
  
  .react-joyride__tooltip {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: black;
    max-width: 300px;
    padding: 15px;
    z-index: 10000;
    border: 2px solid red; /* Add this line for debugging */
  }
  
  .react-joyride__tooltip-title {
    font-weight: bold;
    margin: 0 0 10px;
  }
  
  .react-joyride__tooltip-content {
    margin-bottom: 10px;
  }
  
  .react-joyride__button {
    background: #007bff;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    padding: 8px 12px;
  }
  
  .react-joyride__button--primary {
    background: #007bff;
  }
  
  .react-joyride__button--back {
    background: #6c757d;
  }
  
  .react-joyride__beacon {
    position: absolute;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5);
    animation: pulse 1.5s infinite;
  }
  
  .react-joyride__beacon-inner {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #007bff;
    border-radius: 50%;
  }
  
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5);
    }
    70% {
      box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
    }
  }
  